import { useRouter } from "vue-router/composables"
import {
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction, useVuetify } from "@/composables"

export function usePersonValidation(onFinish = () => {}) {
  const { currentStep } = useNamespacedState("flow/guest", ["currentStep"])

  const { guest } = useNamespacedState("guest", ["guest"])
  const { auth, contacts } = useNamespacedState("guest/auth", [
    "auth",
    "contacts",
  ])
  const { changeAuth } = useNamespacedMutations("guest/auth", ["changeAuth"])
  const { sendPin, isLoading, hasError } = useStoreAction(
    "guest/auth",
    "sendPin"
  )

  const router = useRouter()
  const vuetify = useVuetify()

  const sendGuestPin = async () => {
    await sendPin(guest.value)
    if (vuetify.breakpoint.smAndDown) {
      router.push({ name: "person-token-validation" })
    } else {
      onFinish()
    }
  }

  return {
    isLoading,
    hasError,
    auth,
    contacts,
    changeAuth,
    currentStep,
    sendGuestPin,
  }
}
